import {
  ComponentPropsWithoutRef,
  forwardRef,
  LegacyRef,
  useState,
} from 'react';
import * as VisuallyHidden from '@radix-ui/react-visually-hidden';
import { SupportedLocale } from '@zep/consts/localize.ts';
import { useBodyAttribute } from '@zep/hooks';
import { GNB_BUTTON_INFO, HomeLogo } from '@zep/layout';
import { useUser } from '@zep/lib/auth';
import {
  Button,
  ButtonProps,
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from '@zep/ui';
import { cn } from '@zep/utils';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { EnterCodeButton, MyQuizButton } from './PageNavigatorButton';
import {
  CreateNewQuizButton,
  EditNicknameMbButton,
  LogInButton,
  LogOutButton,
} from './UserManagementButton';

interface TriggerContentProps extends ComponentPropsWithoutRef<'img'> {}

export const TriggerContent = forwardRef<HTMLImageElement, TriggerContentProps>(
  (props: ComponentPropsWithoutRef<'img'>, ref) => {
    return (
      <div className="w-[40px] md:hidden">
        <img
          src="/assets/icon_menu.svg"
          className="cursor-pointer p-xs hover:rounded-[8px] hover:bg-[#3E3E470A] md:hidden"
          {...props}
          ref={ref as LegacyRef<HTMLImageElement>}
          alt="menuicon"
        />
      </div>
    );
  },
);
TriggerContent.displayName = 'TriggerContent';

export const MobileDrawer = () => {
  const { user } = useUser();
  const router = useRouter();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation();
  const isStickBannerShow =
    useBodyAttribute('data-is-stick-banner-show') === 'true';

  const toggleOpen = () => {
    setIsOpen(prev => !prev);
  };

  return (
    <>
      {isOpen && (
        <div
          className="fixed inset-0 z-50 bg-dimmed"
          onClick={() => setIsOpen(false)}></div>
      )}
      <Drawer
        open={isOpen}
        onOpenChange={setIsOpen}
        direction="top"
        modal={false}
        snapPoints={[]}
        fadeFromIndex={0}>
        <DrawerTrigger asChild>
          <TriggerContent onClick={() => setIsOpen(true)} />
        </DrawerTrigger>
        <DrawerContent
          aria-describedby={undefined}
          className={cn(
            ' bg-white z-40 inset-x-0 top-[64px] flex h-fit rounded-none shadow-[0_12px_16px_4px_#1c1b1f1f] focus:outline-none md:hidden',
            `${!isStickBannerShow && 'top-0'}`,
          )}>
          <DrawerHeader className="flex items-center justify-between border-b-[1px_solid_#ededed] px-md py-sm">
            <VisuallyHidden.Root>
              <DrawerTitle />
            </VisuallyHidden.Root>
            <HomeLogo location={'header'} />
            <DrawerClose className="cursor-pointer rounded-[8px] p-[8px] hover:bg-[#3E3E47]/[.04]">
              <img
                src="/assets/icon_close_gray.svg"
                className="size-[24px]"
                alt="closeicon"
              />
            </DrawerClose>
          </DrawerHeader>
          `
          <div className="flex flex-col gap-xs p-md">
            <DrawerButton
              hide={false}
              onClick={() => {
                router.push(GNB_BUTTON_INFO['pricing'].link);
                toggleOpen();
              }}>
              {t(GNB_BUTTON_INFO['pricing'].text)}
            </DrawerButton>
            <DrawerButton
              hide={false}
              onClick={() => {
                router.push(GNB_BUTTON_INFO['public'].link);
                toggleOpen();
              }}>
              {t(GNB_BUTTON_INFO['public'].text)}
            </DrawerButton>
            <DrawerButton
              hide={GNB_BUTTON_INFO['guide'].hideLocales.includes(
                resolvedLanguage as SupportedLocale,
              )}
              onClick={() => {
                window.open(t(GNB_BUTTON_INFO['guide'].link));
                toggleOpen();
              }}>
              {t(GNB_BUTTON_INFO['guide'].text)}
            </DrawerButton>
            <DrawerButton
              hide={GNB_BUTTON_INFO['notice'].hideLocales.includes(
                resolvedLanguage as SupportedLocale,
              )}
              onClick={() => {
                window.open(t(GNB_BUTTON_INFO['notice'].link));
                toggleOpen();
              }}>
              {t(GNB_BUTTON_INFO['notice'].text)}
            </DrawerButton>
          </div>
          <div className="my-sm h-4xs w-full bg-muted" />
          <DrawerFooter className="mt-0 pt-0">
            {user ? (
              <div className="flex flex-col gap-sm">
                <div className="flex items-center justify-between py-[20px]">
                  <EditNicknameMbButton />
                  <LogOutButton isDrawerOpen={isOpen} />
                </div>
                <MyQuizButton.Drawer onClick={toggleOpen} />
              </div>
            ) : (
              <LogInButton type={'drawer'} />
            )}
            <CreateNewQuizButton type={'drawer'} />
            <EnterCodeButton type={'drawer'} />
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

const DrawerButton = ({ hide, ...props }: ButtonProps & { hide: boolean }) =>
  hide ? null : (
    <Button
      size={'lg'}
      intent={'neutral'}
      className={'flex justify-start px-[16px]'}
      transParented
      {...props}
    />
  );
